<template>
  <div>
    <div class="w-100 bg-main-color" style="height: 2px;"></div>
    <hot-recommend :hotGoodsList="hotGoodsList"></hot-recommend>
    <div class="w-1200 bg-white mt-2">
      <div class="df-ac text-grey">
        <div class="Category-name px-2 py border font-s-1 mr-1">服装</div>
        <div class="iconfont icon-qianjin"></div>
        <div class="Category-name px-2 py border font-s-1 mr-1">服装</div>
        <div class="iconfont icon-qianjin"></div>
        <div class="Category-name px-2 py border font-s-1 mr-1">服装</div>
      </div>
      <div class="w-100 mt-2 ">
        <div class="screen-box df-ac">
          <div class="pt-2 pl-3 pb-5 pr-2" style="">品牌:</div>
          <div class="flex-1 d-f flex-wrap">
            <div class="border brand-box ml-1 my" v-for="(item,index) in brandList" :key="index">
              <img v-lazy="item.cover" class="brand-img"/>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 w-100 border font-s-1" style="height: 40px;">
        <div>
          <div class="px-2 df-ac" style="height: 40px;">
            <div class="array-style df-ac" @click="comprehensiveClick" :class="{ active: choiceIndex == -1 }">
              <div class="array-name">综合</div>
              <i class="iconfont ml mr-2"></i>
              <div class="border-right array-style" style="width: 1px;height: 25px;"></div>
            </div>
            <div class="array-style df-ac" v-for="(item, index) in choiceList" :key="index"
                 @click="clickChoice(item,index)"
                 :class="{ active: choiceIndex == index }">
              <div class="array-name">{{ item.choiceName }}</div>
              <i class="iconfont ml mr-2 icon-xiangxia" v-show="!item.searchStatus"></i>
              <i class="iconfont ml mr-2 icon-xiangshang" v-show="item.searchStatus"></i>
              <div class="border-right array-style" style="width: 1px;height: 25px;"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 w-100">
        <div style="width: 1000px;float: left;">
          <!-- 左边列表 -->
          <common-goods-list :goodsList="categoryGoodsList"></common-goods-list>
          <!-- 分页区域 -->
          <el-pagination
            :current-page="categoryQueryInfo.page"
            :page-sizes="[20, 50, 100]"
            :page-size="categoryQueryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"/>
        </div>
        <!-- 右边相关推荐 -->
        <right-goods-list :goodsList="recommendList"></right-goods-list>
      </div>
    </div>
  </div>
</template>

<script>
import  hotRecommend  from '@/components/productList/HotRecommend.vue'
import  commonGoodsList  from '@/components/productList/CommonGoodsList.vue'
import  rightGoodsList  from '@/components/productList/RightGoodsList.vue'
import { recommendGoods, getHotGoods, getCategoryList as getCategoryListUrl } from '@/api/index'

export default {
  components: {
    hotRecommend,
    commonGoodsList,
    rightGoodsList
  },
  data () {
    return {
      categoryGoodsList: [],
      brandList: [],
      arrayIndex: 0,
      choiceIndex: -1,
      choiceList: [
        { choiceName: '销量', searchStatus: false },
        { choiceName: '价格', searchStatus: false },
      ],
      recommendList: [],
      hotGoodsList: [],
      categoryQueryInfo: {
        categoryId: '',
        pageSize: 20,
        page: 1,
        // 搜索条件 soldCount=销量排序 price=价格排序
        field: '',
        // 排序顺序 desc=升序 asc=降序
        desc: ''
      },
      total: 0
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      if (this.$route.query.categoryId != undefined) {
        this.categoryQueryInfo.categoryId = this.$route.query.categoryId
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      /*console.log("%c123","color:red")*/
      this.getRecommendGoods()
      this.getHotGoods()
      this.getCategoryGoodsList()
      this.getBrandList()
      setTimeout(()=>{
        this.changeLoading(loading)
      },300)
    },
    // 关闭loading弹窗
    changeLoading(loading){
      loading.close();
    },
    clickArray (index) {
      this.arrayIndex = index
    },
    // 顶部热门推荐
    async getHotGoods () {
      const { data: res } = await this.$http.get(getHotGoods)
      if (res.code !== 200) return this.$message.error(res.msg)
      console.log(res)
      this.hotGoodsList = res.data.list
    },
    // 右侧推荐商品
    async getRecommendGoods () {
      const { data: res } = await this.$http.get(recommendGoods)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.recommendList = res.data.list
    },
    // 获取分类商品列表
    async getCategoryGoodsList () {
      if (this.categoryQueryInfo.categoryId === '') {
        return this.$message.error('分类错误')
      }
      const { data: res } = await this.$http.get(getCategoryListUrl, { params: this.categoryQueryInfo })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.categoryGoodsList = res.data.list
      this.total = res.data.total
    },
    // 获取品牌列表
    async getBrandList () {
      this.brandList = this.$store.state.commonBrandList
      // 如果商品列表小于0，从actions中获取接口数据，重新渲染品牌列表
      if (this.brandList.length <= 0) {
        await this.$store.dispatch('changeCommonBrandListActions')
        this.brandList = this.$store.state.commonBrandList
      }
    },
    // 分页页码显示条数
    handleSizeChange (newSzie) {
      // 把每页显示多少数据重新赋值
      this.categoryQueryInfo.pageSize = newSzie
      // 重新获取数据并显示
      this.getCategoryGoodsList()
    },
    // 页面切换事件
    handleCurrentChange (newPage) {
      this.categoryQueryInfo.page = newPage
      // 重新获取数据并显示
      this.getCategoryGoodsList()
    },
    // 顶部销量、价格点击事件
    clickChoice (searchItem, index) {
      console.log(searchItem)
      if (this.choiceIndex !== index) {
        this.choiceIndex = index
        this.changeQueryObj(searchItem.choiceName, searchItem.searchStatus)
        this.getCategoryGoodsList()
      } else {
        searchItem.searchStatus = !searchItem.searchStatus
        this.changeQueryObj(searchItem.choiceName, searchItem.searchStatus)
        this.getCategoryGoodsList()
      }
    },
    // 组织查询条件参数
    changeQueryObj (queryName, searchStatus) {
      if (queryName === '销量' && !searchStatus) {
        this.categoryQueryInfo.pageSize = 20
        this.categoryQueryInfo.page = 1
        this.categoryQueryInfo.field = 'soldCount'
        this.categoryQueryInfo.desc = 'desc'
      } else if (queryName === '销量' && searchStatus) {
        this.categoryQueryInfo.pageSize = 20
        this.categoryQueryInfo.page = 1
        this.categoryQueryInfo.field = 'soldCount'
        this.categoryQueryInfo.desc = 'asc'
      } else if (queryName === '价格' && !searchStatus) {
        this.categoryQueryInfo.pageSize = 20
        this.categoryQueryInfo.page = 1
        this.categoryQueryInfo.field = 'price'
        this.categoryQueryInfo.desc = 'desc'
      } else {
        this.categoryQueryInfo.pageSize = 20
        this.categoryQueryInfo.page = 1
        this.categoryQueryInfo.field = 'price'
        this.categoryQueryInfo.desc = 'asc'
      }
    },
    // 综合点击事件
    comprehensiveClick () {
      // 重置查询条件
      this.categoryQueryInfo.pageSize = 20
      this.categoryQueryInfo.page = 1
      this.categoryQueryInfo.field = ''
      this.categoryQueryInfo.desc = ''
      this.choiceIndex = -1
      this.getCategoryGoodsList()
    },
    // 跳转到商品详情页
    toProductDetail (productInfo) {
      console.log(productInfo)
      this.$router.push({ path: '/product', query: { productId: productInfo.id } })
    }
  },
  watch: {
    // 深度监听路由改变事件
    '$route': {
      handler () {
        this.initData()
      },
      deep: true,
      immediate: true,
    }
  },
}
</script>

<style lang="less">

  .screen-box {
    border: 2px #f3f3f3 dashed;
  }

  .brand-box {
    width: 95px;
    height: 40px;
    .brand-img {
      width: 95px ;
      height: 40px;

    }

    .brand-name {
      position: relative;
      z-index: 1;
    }
  }

  .array-style {
    margin-right: 20px;
    cursor: pointer;

    .iconfont {
      font-size: 12px;
    }
  }

  .active .array-name {
    color: #EF001C;;
  }

  .active .iconfont {
    color: #EF001C;;
  }



</style>
